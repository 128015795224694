import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HomeIcon from "./../../assets/images/games-icon/headicon/house.png";
import GamesRuleIcon from "./../../assets/images/icons/Gold-Shine-PNG-Image.png";
import ChallengesIcon from "./../../assets/images/games-icon/cashier.png";
import MegaChallengeTC from "./../../assets/images/games-icon/challenge-tc.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon1.png";
import SoccerIcon from "./../../assets/images/games-icon/Soccer.png";
import TennisIcon from "./../../assets/images/games-icon/Tennis.png";
import { EvolutionGames } from "../../lib/data/evolutiongames.data";

import XPGIcon from "./../../assets/images/games-icon/new/xpg.png";
import WorldCasinoIcon from "./../../assets/images/games-icon/new/slot-machine.png";
import QtechIcon from "./../../assets/images/games-icon/new/qtech.png";
import SupernowaIcon from "./../../assets/images/games-icon/new/supernowa.png";
import AviatorIcon from "./../../assets/images/games-icon/new/paper-plane.png";
import EvolutionIcon from "./../../assets/images/games-icon/new/evolution.png";
import EzugiIcon from "./../../assets/images/games-icon/new/ezugi.png";
import Vivoicon from "./../../assets/images/games-icon/new/casino-chip.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Cricket");
                  }}
                >
                  <img src={CricketIcon} alt="cricket" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Soccer");
                  }}
                >
                  <img src={SoccerIcon} alt="soccer" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Tennis");
                  }}
                >
                  <img src={TennisIcon} alt="tennis" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/ezugi");
                    } else {
                      navigate("/casino/ezugi");
                    }
                  }}
                >
                  <img src={EzugiIcon} alt="mega challenge t&c" />
                  <span>Ezugi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/evolution");
                    } else {
                      navigate("/casino/evolutions");
                    }
                  }}
                >
                  <img src={EvolutionIcon} alt="mega challenge t&c" />
                  <span>Evolution</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/spribe/aviator");
                    } else {
                      navigate("/casino/spribe/aviator");
                    }
                  }}
                >
                  <img src={AviatorIcon} alt="mega challenge t&c" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/xpg");
                    } else {
                      navigate("/casino/xpg");
                    }
                  }}
                >
                  <img src={XPGIcon} alt="mega challenge t&c" />
                  <span>XPG</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/qtech");
                    } else {
                      navigate("/casino/qtech");
                    }
                  }}
                >
                  <img src={QtechIcon} alt="mega challenge t&c" />
                  <span>qtech</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/supernowa");
                    } else {
                      navigate("/casino/supernowa");
                    }
                  }}
                >
                  <img src={SupernowaIcon} alt="mega challenge t&c" />
                  <span>Supernowa</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/vivo");
                    } else {
                      navigate("/casino/vivo");
                    }
                  }}
                >
                  <img src={Vivoicon} alt="mega challenge t&c" />
                  <span>Vivo</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/casino/worldcasino");
                    } else {
                      navigate("/casino/worldcasino");
                    }
                  }}
                >
                  <img src={WorldCasinoIcon} alt="mega challenge t&c" />
                  <span>Worldcasino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/terms");
                    } else {
                      navigate("/termss");
                    }
                  }}
                >
                  <img src={GamesRuleIcon} alt="games rule" />
                  <span>Rules & Regulations</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
//
